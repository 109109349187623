export class WlInputValidation extends Event {

    static type: string = 'wl-invalid-input';
    value: string
    name: string;
    isValid: boolean;

    constructor(value: string, name: string, isValid: boolean) {
        super(WlInputValidation.type, {bubbles: true, composed: true});
        this.value = value
        this.name = name
        this.isValid = isValid
    }
}
