import {css, html, LitElement, nothing} from "lit";
import {pxToRem} from "../styles";
import "../badge/badge";
import "../icon/icon";

export class WlChip extends LitElement {
    protected withCrossIcon: boolean;
    protected number: number | undefined;

    static get properties() {
        return {
            withCrossIcon: {type: Boolean},
            number: {type: Number},
        }
    }

    constructor() {
        super()
        this.withCrossIcon = false;
    }

    static get styles() {
        return css`
            :host {
                display: flex;
                width: max-content;
                font-size: var(--wl-chip-font-size, ${pxToRem(14)});
            }

            .chip {
                display: flex;
                align-items: center;
                cursor: pointer;
                justify-content: space-between;
                gap: ${pxToRem(10)};
                box-sizing: border-box;

                border: var(--wl-chip-border-size, 0) solid var(--wl-chip-border-color, none);
                border-radius: var(--wl-chip-border-radius, 0);
                padding: var(--wl-chip-padding, ${pxToRem(8)});
                min-height: var(--wl-chip-height, ${pxToRem(24)});
                align-items: center;
                background-color: var(--wl-chip-background-color, #f1f1f1);
                color: var(--wl-chip-color, #000);
                
            }

            ::slotted(*) {
                text-overflow: ellipsis;
                overflow: hidden;
            }
            
            .badge {
                width: var(--wl-chip-close-icon-size, ${pxToRem(24)});
                min-width: var(--wl-chip-close-icon-size, ${pxToRem(24)});
                max-width: var(--wl-chip-close-icon-size, ${pxToRem(24)});
                height: var(--wl-chip-close-icon-size, ${pxToRem(24)});
                min-height: var(--wl-chip-close-icon-size, ${pxToRem(24)});
                max-height: var(--wl-chip-close-icon-size, ${pxToRem(24)});
            }
        `
    }


    connectedCallback() {
        super.connectedCallback();
    }

    protected render() {
        return html`
            <div class="chip">
                <slot></slot>
                ${this._renderBadge()}
            </div>
        `
    }

    protected _renderBadge() {
        if (this.number) {
            return html`<wl-badge>${this.number}</wl-badge>`
        }

        if (!this.withCrossIcon) {
            return nothing
        }

        return html`
            <wl-badge class="badge" @click="${this._clickOnCross}"><wl-icon icon="cross"></wl-icon></wl-badge>
        `
    }

    private _clickOnCross() {
        this.dispatchEvent(new WlTagClose(this.innerText));
    }
}

export class WlTagClose extends Event {
    public value: string

    constructor(value: string) {
        super('wl-tag-close', {bubbles: true, composed: true});
        this.value = value
    }
}
