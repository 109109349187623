import {WlAutoComplete} from "./autocomplete.component";

export * from './autocomplete.component';
export * from './autocompleteButton.component';
export * from './autocompleteContent.component';
export * from './autocompleteOption.component';
export default WlAutoComplete;

export const AUTOCOMPLETE_TAG = 'wl-autocomplete';
customElements.define(AUTOCOMPLETE_TAG, WlAutoComplete);
