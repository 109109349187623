import {css, html, LitElement} from "lit";
import {pxToRem} from "../styles";
import {WlChecked} from "../events/WlChecked";
import {PropertyValues} from "@lit/reactive-element";

export class WlRadioButton extends LitElement {
    public checked: boolean;
    public value: string;
    private radioButton: HTMLInputElement | null;

    static formAssociated = true;

    // @ts-ignore
    private _internals: ElementInternals & IElementInternals;

    static get properties() {
        return {
            checked: {type: Boolean, reflect: true},
            value: {type: String, reflect: true},
        }
    }

    constructor() {
        super()
        this._internals = this.attachInternals();
        this.checked = false;
        this.value = "";
        this.radioButton = null;
    }

    protected firstUpdated(_changedProperties: PropertyValues) {
        this.radioButton = this.renderRoot.querySelector("input")!
        this.radioButton.checked = this.checked
    }

    updated() {
        this.updateRadioButton()
    }

    private updateRadioButton() {
        this.radioButton!.checked = this.checked
        this.setFormValues();
    }

    private setFormValues() {
        this._internals.setFormValue(null);
        if (this.checked) {
            this._internals.setFormValue(this.value);
        }
    }

    static get styles() {
        return css`
            :host {
                display: block;
                font-size: var(--wl-item-font-size, var(--wl-control-font-size, ${pxToRem(14)}));
                color: var(--wl-radio-button-color, #000000);
                padding: var(--wl-radio-button-padding, 0);
            }

            :host([checked]) {
                color: var(--wl-radio-button-checked-color, red);
                font-weight: var(--wl-radio-button-checked-font-weight, 700);
            }

            label {
                cursor: pointer;
                display: flex;
                align-items: center;
                width: var(--wl-radio-button-width, auto)   ;
                column-gap: var(--wl-radio-button-gap, ${pxToRem(8)});
            }

            .radio-button {
                box-sizing: border-box;
                width: ${pxToRem(24)};
                height: ${pxToRem(24)};
                padding: 0;
                position: relative;
                display: inline-block;
            }

            input[type="radio"] {
                display: none;
            }

            .radio-button::before {
                content: '';
                display: inline-block;
                height: ${pxToRem(24)};
                width: ${pxToRem(24)};
                margin: 0;
                padding: 0;
                background-color: transparent;
                outline: none;
                background-image: var(--wl-radio-button-icon-url, url(https://portals-whitelabel-statics-prd.s3.us-west-2.amazonaws.com/properati/images/radio-button-unchecked.svg))
            }

            :host([checked]) .radio-button::before {
                background-image: var(--wl-radio-button-checked-icon-url, url(https://portals-whitelabel-statics-prd.s3.us-west-2.amazonaws.com/properati/images/radio-button-checked.svg))
            }
        `
    }

    protected render() {
        return html`
            <label>
                <div class="radio-button"></div>
                <input
                        type="radio"
                        ${this.value ? `value="${this.value}"` : ""}
                        @change="${(e: Event) => {
                            this.checked = this.radioButton?.checked || false;
                            this.dispatchEvent(new WlChecked(this.checked))
                        }}"
                        ${this.checked ? "checked" : ""}
                />
                <slot></slot>
            </label>
        `
    }

    unCheck() {
        this.checked = false;
        this.updateRadioButton();
    }

    label() {
        return this.innerText;
    }
}
