import {css, html, LitElement, nothing} from "lit";
import {Value} from "../core/Value";
import {PropertyValues} from "@lit/reactive-element";
import {pxToRem} from "../styles";

export class AutocompleteButton extends LitElement {
    public open: boolean
    private placeholder: string
    public selectedOptions: Value[]
    public value : string
    public multiValueLabel : string
    private input: HTMLInputElement | null

    constructor() {
        super();
        this.open = false;
        this.placeholder = "";
        this.selectedOptions = [];
        this.value = "";
        this.input = null
        this.multiValueLabel = "Values"
    }

    static get properties() {
        return {
            placeholder: {type: String},
            open: {type: Boolean},
            selectedOptions: { type: Array },
            multiValueLabel: { type: String, attribute: "multi-value-label" },
        };
    }

    static get styles() {
        return [css`
            :host {
                width: var(--wl-control-width, 400px);
                font-size: var(--wl-control-font-size, ${pxToRem(18)});
                padding: 0 ${pxToRem(16)};
            }

            .summary {
                display: flex;
                width: 100%;
                flex-direction: row;
                justify-content: space-between;
                color: #0A0A0A;
                gap: ${pxToRem(6)};
                align-items: center;
            }

            input {
                border: none;
                flex: 1 1 0;
                font-size: var(--wl-control-font-size, ${pxToRem(18)});
            }
            
            input ::placeholder {
                color: --var(wl-placeholder-color, #8F939F); 
            }

            input:focus {
                outline: none;
            }

            .magnifier {
                min-width: 24px;
                height: 24px;
                position: absolute;
                right: ${pxToRem(16)};
            }
            
            wl-chip {
                max-width: ${pxToRem(150)};
                text-wrap: nowrap;
            }
        `]
    }

    protected render() {
        return html`
            <div class="summary">
                ${!this.open ? html`${this._renderButtonSummary()}` : nothing}
                <input type="text" placeholder="${this.placeholder}" @keyup="${this.changeValue}">
                ${this._renderIcon()}
            </div>
        `
    }

    private _renderButtonSummary() {
        if (this.selectedOptions.length == 0) return nothing
        if (this.selectedOptions.length == 1) return html`
            <wl-chip><span>${this.truncate(this.selectedOptions[0].label, 15)}</span></wl-chip>`

        return html`<wl-chip number="${this.selectedOptions.length}">${this.multiValueLabel}</wl-chip>`
    }


    private truncate = (input: string, size: number) => input.length > size ? `${input.substring(0, size)}...` : input;

    private _renderIcon() {
        return html`<svg class="magnifier" width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" 
                  clip-rule="evenodd" 
                  d="M10.422 14.7535C9.57502 15.0833 8.6528 15.2645 7.68796 15.2645C3.55248 15.2645 0.200012 11.9369 0.200012 7.83224C0.200012 3.72754 3.55248 0.400024 7.68796 0.400024C11.8234 0.400024 15.1759 3.72754 15.1759 7.83224C15.1759 10.0874 14.164 12.1079 12.5663 13.4709L15.5675 17.6412C15.9681 18.1979 15.8382 18.9716 15.2773 19.3692C14.7164 19.7668 13.937 19.6379 13.5364 19.0812L10.422 14.7535ZM7.68796 12.7871C10.445 12.7871 12.6799 10.5687 12.6799 7.83224C12.6799 5.09578 10.445 2.87743 7.68796 2.87743C4.93098 2.87743 2.696 5.09578 2.696 7.83224C2.696 10.5687 4.93098 12.7871 7.68796 12.7871Z" 
                  fill="currentColor"/>
        </svg>
        `
    }

    protected firstUpdated(_changedProperties: PropertyValues) {
        super.firstUpdated(_changedProperties)
        this.input = this.shadowRoot?.querySelector("input") || null
    }

    protected update(changedProperties: PropertyValues) {
        super.update(changedProperties)
        if (changedProperties.has("open")) {
            if (this.open) {
                this.input?.focus()
            } else {
                this.input?.blur()
            }
        }
    }

    private changeValue(event: Event) {
        event.stopPropagation()

        this.value = this.input?.value || ""
        this.dispatchEvent(new CustomEvent("wl-change", {bubbles: true, composed: true}))
    }
}

customElements.define('wl-autocomplete-button', AutocompleteButton);