import {css, html, LitElement} from "lit";
// @ts-ignore
import {WlChecked} from "../events/WlChecked";
import {PropertyValues} from "@lit/reactive-element";

export class WlCheckbox extends LitElement {
    public checked: boolean;
    public indeterminate: boolean;
    public value: string;
    private checkbox: HTMLInputElement | null;

    static formAssociated = true;

    // @ts-ignore
    private _internals: ElementInternals & IElementInternals;

    static get properties() {
        return {
            checked: {type: Boolean, reflect: true},
            indeterminate: {type: Boolean, reflect: true},
            value: {type: String, reflect: true},
        }
    }

    constructor() {
        super()
        this._internals = this.attachInternals();
        this.checked = false;
        this.indeterminate = false;
        this.value = "";
        this.checkbox = null;
    }

    protected firstUpdated(_changedProperties: PropertyValues) {
        this.checkbox = this.renderRoot.querySelector("input")!
        this.checkbox.checked = this.checked
    }

    updated() {
        this.updateCheckbox()
    }

    private updateCheckbox() {
        this.checkbox!.checked = this.checked
        this.checkbox!.indeterminate = this.indeterminate
        this.setFormValues();
    }

    private setFormValues() {
        this._internals.setFormValue(null);
        if (this.checked) {
            this._internals.setFormValue(this.value);
        }
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }

            label {
                cursor: pointer;
                display: flex;
                align-items: center;
            }

            label {
                cursor: pointer;
                display: flex;
                align-items: var(--wl-checkbox-align-items, center);
            }
            
            label.checked {
                color: var(--wl-checkbox-checked-color, inherit);
                font-weight: var(--wl-checkbox-checked-font-weight, normal);
            }

            .checkbox {
                box-sizing: border-box;
                margin: 0 10px 0 0;
                width: 20px;
                height: 20px;
                padding: 0;
                position: relative;
                display: inline-block;
            }
            

            input[type="checkbox"] {
                display: none;
                box-sizing: border-box;
                opacity: 0;
                position: absolute;
            }

            .icon {
                min-width: 18px;
                display: inline-block;
                height: 18px;
                width: 18px;
                margin: 0;
                padding: 0;
                border: var(--wl-checkbox-border-width, 1px) solid var(--wl-checkbox-border-color, #AAA);
                border-radius: var(--wl-checkbox-radius, 2px) ;
                background-color: transparent;
                outline: none;
            }

            .icon > i {
                display: inline-block;
                height: 18px;
                width: 18px;
            }

            input[type="checkbox"]:checked ~ .icon {
                background-color: var(--wl-checkbox-checked-background-color, green);
                border-color: var(--wl-checkbox-checked-background-color, green);
            }

            input[type="checkbox"]:checked ~ .icon > i {
                background-color: var(--wl-checkbox-checked-tick-color, white);
                clip-path: polygon(23% 43%, 42% 64%, 77% 31%, 85% 36%, 41% 75%, 16% 49%);
            }

            input[type="checkbox"]:indeterminate ~ .icon {
                background-color: var(--wl-checkbox-checked-background-color, green);
                border-color: var(--wl-checkbox-checked-background-color, green);
            }

            input[type="checkbox"]:indeterminate ~ .icon > i {
                background-color: var(--wl-checkbox-checked-tick-color, white);
                clip-path: polygon(15% 44%, 15% 56%, 85% 56%, 85% 44%)
            }
        `
    }

    protected render() {
        return html`
            <label class="${this.checked ? "checked" : ""}">
                <div class="checkbox">
                    <input
                            type="checkbox"
                            ${this.value ? `value="${this.value}"` : ""}
                            @change="${(e: Event) => {
                                this.checked = this.checkbox?.checked || false;
                                this.indeterminate = this.checkbox?.indeterminate || false;
                                this.dispatchEvent(new WlChecked(this.checked))
                            }}"
                            ${this.checked ? "checked" : ""}
                            ${this.indeterminate ? "indeterminate" : ""}
                    >
                    <span class="icon"><i></i></span>
                    </input>
                </div>
                <slot></slot>
            </label>
        `
    }

    unCheck() {
        this.checked = false;
        this.indeterminate = false;
        this.updateCheckbox();
    }

    label() {
        return this.innerText;
    }
}
