export class WlInput extends Event {

    static type: string = 'wl-input-change';
    value: string
    name: string;

    constructor(value: string, name: string) {
        super(WlInput.type, {bubbles: true, composed: true});
        this.value = value
        this.name = name
    }
}
