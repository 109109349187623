import {css, html, LitElement, nothing} from "lit";
import {pxToRem} from "../styles";
import {PropertyValues} from "@lit/reactive-element";
import {WlInputValidation} from "../events/WlInputValidation";

export class WlIntegerInput extends LitElement {
    private _internals: ElementInternals;
    public value: number | null;
    public placeholder: string;
    public input: HTMLInputElement | null;
    public title: string;
    public label: string;
    private readonly minValue: number | null;
    private readonly maxValue: number | null;
    private readonly locale: string;
    private errormessage: string;
    private hasError: boolean;
    private isRequired: boolean = false;

    static formAssociated = true; // This is required to use the this._internals.setFormValue() method

    static get properties() {
        return {
            value: {
                type: String, reflect: true, converter: {
                    fromAttribute(value: string) {
                        if (value == "") {
                            return null
                        }
                        return parseInt(value.replace(".0", ""))
                    },
                }
            },
            placeholder: {type: String, reflect: true},
            title: {type: String, reflect: true},
            minValue: {type: Number, reflect: true},
            maxValue: {type: Number, reflect: true},
            locale: {
                type: String, reflect: true, converter: {
                    fromAttribute(value: string) {
                        return value.replace("_", "-");
                    },
                }
            },
            errormessage: {type: String},
            label: {type: String, attribute: false},
            isRequired: {type: Boolean}
        }
    }

    constructor() {
        super();
        // @ts-ignore
        this._internals = this.attachInternals();
        this.value = null;
        this.placeholder = "";
        this.label = ""
        this.title = "";
        this.minValue = null;
        this.maxValue = null;
        this.locale = "th-TH";
        this.input = null;
        this.errormessage = "";
        this.hasError = false;
    }

    static get styles() {
        return css`
            :host {
                flex-basis: calc((100% - 4px) / 2);
            }

            .title {
                font-size: var(--wl-range-item-label-font-size, ${pxToRem(14)});
                font-weight: var(--wl-range-item-label-font-weight, 600);
                color: var(--wl-range-item-label-color, #000000);
            }

            .error-message {
                color: red;
                opacity: 1;
                transition: opacity 1s;
                font-size: ${pxToRem(10)};
            }
            
            .error {
                border-color: #ff0000;
                outline: none;
            }

            input {
                max-width: 100%;
                width: calc(100% - 22px);
                height: var(--wl-range-item-height, ${pxToRem(34)});
                padding: 0 ${pxToRem(10)};
                font-size: ${pxToRem(14)};
                color: var(--wl-range-item-color, #000);
                border: 1px solid var(--wl-range-item-border-color, #000);
                border-radius: ${pxToRem(4)};
            }

            input:focus {
                outline: none;
                border: 1px solid var(--wl-range-item-border-color-focus, #000);
            }
            input:focus.error {
                border-color: #ff0000;
            }
        `
    }

    protected render() {
        return html`
            ${this._renderLabel()}
            <input type="text" value="${this.label}" placeholder="${this.placeholder}"
                   @change="${this.updateValueFromInput}" @keyup="${this.updateValueFromInput}"
            />
            ${this._renderError()}
        `
    }

    protected update(_changedProperties: PropertyValues) {
        super.update(_changedProperties);
        this.label = this.format(this.value);
        this._internals.setFormValue(this.value?.toString() || null);
    }

    protected updated(_changedProperties: PropertyValues) {
        super.updated(_changedProperties);
        if (this.input != null) {
            this.input!!.value = this.label
        }

        if (_changedProperties.has("value")) {
            if (this.isInvalidValue()) {
                this.showError()
                return;
            }

            this.hideError()
            this.dispatchEvent(new CustomEvent("wl-change", {bubbles: true, composed: true}));
        }
    }

    protected firstUpdated(_changedProperties: PropertyValues) {
        this.input = this.renderRoot.querySelector("input")!;
    }

    private _renderLabel() {
        if (this.title != '') {
            return html`<label class="title">${this.title}</label>`;
        }
        return nothing;
    }

    private _renderError() {
        if (this.errormessage !== '' && this.hasError) {
            return html`<span class="error-message">${this.errormessage}</span>`;
        }
        return nothing;
    }

    clear() {
        this.value = null
    }

    checkValidity(): boolean {
        return this._internals.checkValidity();
    }

    private updateValueFromInput() {
        let parse = this.parse(this.input?.value || "", this.locale);
        this.value = parse;

        if (this.input) {
            if (parse) {
                this.input.value = this.format(parse);
            } else {
                this.input.value = ""
            }
        }
    }

    private format(value: number | null) {
        if (value == null || Number.isNaN(value)) {
            return ""
        }

        return this.formatNumber(value);
    }

    private isOutOfLimits(value: number) {
        let minValue = 0;
        if (this.minValue) {
            minValue = this.minValue
        }
        return value < minValue || (this.maxValue && value > this.maxValue);
    }

    private formatNumber(value: number) {
        return value.toLocaleString(this.locale);
    }

    private showError() {
        this.errormessage = this.createErrorMessage()
        this._internals.setValidity({valueMissing: true}, this.errormessage);
        this.dispatchEvent(new WlInputValidation(this.value + "", "this.n", false));
        // setTimeout(this.hideError, 2000);
        this.input?.classList.add("error");
        this.hasError = true;
    }

    private hideError() {
        this._internals.setValidity({valueMissing: false});
        this.dispatchEvent(new WlInputValidation(this.value + "", "this.n", true));
        this.input?.classList.remove("error");
        this.hasError = false;
    }

    private createErrorMessage() {
        if (this.errormessage) {
            return this.errormessage;
        }
        let message = ' (> 0)';
        if (this.maxValue) {
            message = ` (0-${this.formatNumber(this.maxValue)})`;
        }
        return message;
    }

    private parse(number: string, locale: string): number | null {
        const formatter = new Intl.NumberFormat(locale);
        const parts = formatter.formatToParts(12345.6);
        const groupSeparator = parts.find(part => part.type === 'group')!!.value;
        const decimalSeparator = parts.find(part => part.type === 'decimal')!!.value;

        const normalilzedNumber = number
            .replace(new RegExp(`\\${groupSeparator}`, 'g'), '')
            .replace(new RegExp(`\\${decimalSeparator}`), '.')
            .replace(/[^0-9]/g, "");

        let num = parseFloat(normalilzedNumber);
        if (isNaN(num)) {
            return null;
        }
        return num
    }

    private isInvalidValue() {
        if (this.isRequired && this.value == null) {
            return true;
        }
        if (this.value != null) {
            return this.isOutOfLimits(this.value);
        }
        return false;
    }
}
