import {css, html, LitElement, unsafeCSS} from "lit";
import {pxToRem} from "../styles";
import {PropertyValues} from "@lit/reactive-element";

const TYPE = {
    SUBMIT: "submit",
    CLEAR: "clear",
}

const VARIANT = {
     FILLED: "filled",
     OUTLINED: "outlined",
     FLAT: "flat",
}

const COLOR= {
    PRIMARY: "primary",
    SECONDARY: "secondary",
}

const SIZE = {
    LARGE: "large",
    MEDIUM: "medium",
    SMALL: "small",
}

export const BUTTON = {
    TYPE,
    VARIANT,
    COLOR,
    SIZE,
}

const DEFAULT_BORDER = 0;
const DEFAULT_FONT_WEIGHT = 700;
const DEFAULT_BORDER_RADIUS = pxToRem(4);

export class WlBrandedButton extends LitElement {
    private type: string;
    private variant: string;
    private color: string;
    private size: string;
    disabled: boolean;

    constructor() {
        super();
        this.type = TYPE.SUBMIT;
        this.variant = VARIANT.FILLED;
        this.color = COLOR.PRIMARY;
        this.size = SIZE.MEDIUM;
        this.disabled = false;
    }

    connectedCallback() {
        super.connectedCallback();
        if(!Object.values(TYPE).includes(this.type)) {
            this.type = TYPE.SUBMIT;
        }
        if(!Object.values(VARIANT).includes(this.variant)) {
            this.variant = VARIANT.FILLED;
        }
        if(!Object.values(COLOR).includes(this.color)) {
            this.color = COLOR.PRIMARY;
        }
        if(!Object.values(SIZE).includes(this.size)) {
            this.color = SIZE.MEDIUM;
        }
    }

    static get properties() {
        return {
            type: {type: String},
            disabled: {type: Boolean, reflect: true},
            badge: {type: String},
        };
    }

    static get styles() {
        return css`
            :host {
                width: var(--wl-button-width);
            }

            button {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                vertical-align: middle;
                width: var(--wl-button-width, 100%);
                padding: var(--wl-button-padding, revert);
                color: var(--wl-button-color, white);
                background-color: var(--wl-button-background-color, grey);
                height: var(--wl-button-height, ${pxToRem(35)});
                border-style: none;
                border-radius: var(--wl-button-border-radius, ${DEFAULT_BORDER_RADIUS});
                font-family: var(--wl-button-font-family, inherit);
                font-weight: var(--wl-button-primary-font-weight, ${DEFAULT_FONT_WEIGHT});
                font-size: var(--wl-button-font-size, revert);
                gap: var(--wl-button-gap, ${pxToRem(5)});
            }
            
            /* Variants */
            :host([variant="${unsafeCSS(VARIANT.FILLED)}"]) button {
                border: var(--wl-button-variant-filled-border, ${DEFAULT_BORDER});
                font-weight: var(--wl-button-variant-filled-font-weight, ${DEFAULT_FONT_WEIGHT});
            }
            
            :host([variant="${unsafeCSS(VARIANT.OUTLINED)}"]) button {
                border: var(--wl-button-variant-outlined-border, ${DEFAULT_BORDER});
                font-weight: var(--wl-button-variant-outlined-font-weight, ${DEFAULT_FONT_WEIGHT});
            }
            
            :host([variant="${unsafeCSS(VARIANT.FLAT)}"]) button {
                border: var(--wl-button-variant-flat-border, ${DEFAULT_BORDER});
                font-weight: var(--wl-button-variant-flat-font-weight, ${DEFAULT_FONT_WEIGHT});
            }

            /* Colors */
            :host([variant="${unsafeCSS(VARIANT.FILLED)}"][color="${unsafeCSS(COLOR.PRIMARY)}"]) button {
                color: var(--wl-button-secondary-background-color, white);
                background-color: var(--wl-button-background-color, grey);
                border-color: var(--wl-button-background-color, white);
            }

            :host([variant="${unsafeCSS(VARIANT.FILLED)}"][color="${unsafeCSS(COLOR.PRIMARY)}"]) button:hover {
                background-color: var(--wl-button-primary-hover-dark, grey);
            }

            :host([variant="${unsafeCSS(VARIANT.OUTLINED)}"][color="${unsafeCSS(COLOR.PRIMARY)}"]) button {
                color: var(--wl-button-background-color, white);
                background-color: var(--wl-button-secondary-background-color, grey);
                border-color: var(--wl-button-border-color, white);
            }

            :host([variant="${unsafeCSS(VARIANT.OUTLINED)}"][color="${unsafeCSS(COLOR.PRIMARY)}"]) button:hover {
                background-color: var(--wl-button-primary-hover-light, grey);
            }

            :host([variant="${unsafeCSS(VARIANT.FLAT)}"][color="${unsafeCSS(COLOR.PRIMARY)}"]) button {
                color: var(--wl-button-background-color, white);
                background-color: var(--wl-button-secondary-background-color, grey);
                border-color: var(--wl-button-secondary-background-color, white);
            }

            :host([variant="${unsafeCSS(VARIANT.FLAT)}"][color="${unsafeCSS(COLOR.PRIMARY)}"]) button:hover {
                background-color: var(--wl-button-primary-hover-light, grey);
            }
            
            :host([variant="${unsafeCSS(VARIANT.FILLED)}"][color="${unsafeCSS(COLOR.SECONDARY)}"]) button {
                color: var(--wl-button-secondary-background-color, white);
                background-color: var(--wl-button-secondary-color, grey);
                border-color: var(--wl-button-secondary-color, white);
            }
            
            :host([variant="${unsafeCSS(VARIANT.FILLED)}"][color="${unsafeCSS(COLOR.SECONDARY)}"]) button:hover {
                background-color: var(--wl-button-secondary-hover-dark, grey);
            }

            :host([variant="${unsafeCSS(VARIANT.OUTLINED)}"][color="${unsafeCSS(COLOR.SECONDARY)}"]) button {
                color: var(--wl-button-secondary-color, white);
                background-color: var(--wl-button-secondary-background-color, grey);
                border-color: var(--wl-button-secondary-color, white);
            }

            :host([variant="${unsafeCSS(VARIANT.OUTLINED)}"][color="${unsafeCSS(COLOR.SECONDARY)}"]) button:hover {
                background-color: var(--wl-button-secondary-hover-light, grey);
            }

            :host([variant="${unsafeCSS(VARIANT.FLAT)}"][color="${unsafeCSS(COLOR.SECONDARY)}"]) button {
                color: var(--wl-button-secondary-color, white);
                background-color: var(--wl-button-secondary-background-color, grey);
                border-color: var(--wl-button-secondary-background-color, white);
            }

            :host([variant="${unsafeCSS(VARIANT.FLAT)}"][color="${unsafeCSS(COLOR.SECONDARY)}"]) button:hover {
                background-color: var(--wl-button-secondary-hover-light, grey);
            }

            :host([variant="${unsafeCSS(VARIANT.FILLED)}"][disabled]) button {
                color: var(--wl-button-disabled-color-oposite, grey);
                background-color: var(--wl-button-disabled-color, lightgrey);
                border-color: var(--wl-button-disabled-color, grey);
                cursor: default;
            }

            :host([variant="${unsafeCSS(VARIANT.FILLED)}"][disabled]) button:hover {
                color: var(--wl-button-disabled-color-oposite, grey);
                background-color: var(--wl-button-disabled-color, lightgrey);
                border-color: var(--wl-button-disabled-color, grey);
                cursor: default;
            }

            :host([variant="${unsafeCSS(VARIANT.OUTLINED)}"][disabled]) button {
                color: var(--wl-button-disabled-color-oposite, grey);
                background-color: #ffffff;
                border-color: var(--wl-button-disabled-color, grey);
                cursor: default;
            }

            :host([variant="${unsafeCSS(VARIANT.OUTLINED)}"][disabled]) button:hover {
                color: var(--wl-button-disabled-color-oposite, grey);
                background-color: #ffffff;
                border-color: var(--wl-button-disabled-color, grey);
                cursor: default;
            }

            :host([variant="${unsafeCSS(VARIANT.FLAT)}"][disabled]) button {
                color: var(--wl-button-disabled-color-oposite, grey);
                background-color: #ffffff;
                border-color: #ffffff;
                cursor: default;
            }

            :host([variant="${unsafeCSS(VARIANT.FLAT)}"][disabled]) button:hover {
                color: var(--wl-button-disabled-color-oposite, grey);
                background-color: #ffffff;
                border-color: #ffffff;
                cursor: default;
            }
            
            /* Sizes */
            :host([size="${unsafeCSS(SIZE.LARGE)}"]) button {
                padding: var(--wl-button-size-large-padding, revert);
                font-size: var(--wl-button-size-large-font-size, revert);
            }
            
            :host([size="${unsafeCSS(SIZE.MEDIUM)}"]) button {
                padding: var(--wl-button-size-medium-padding, revert);
                font-size: var(--wl-button-size-medium-font-size, revert);
            }
            
            :host([size="${unsafeCSS(SIZE.SMALL)}"]) button {
                padding: var(--wl-button-size-small-padding, revert);
                font-size: var(--wl-button-size-small-font-size, revert);
            }
        `
    }

    render() {
        return html`
            <button type="button" @click="${this.clicked}">
                <slot></slot>
            </button>
        `
    }

    protected update(changedProperties: PropertyValues) {
        super.update(changedProperties)
        if (changedProperties.has("disabled")) {
            let button = this.shadowRoot?.querySelector("button")
            button!!.disabled = this.disabled
        }
    }

    private clicked() {
        let customEvent = "wl-" + this.type;
        this.dispatchEvent(new CustomEvent(customEvent, {bubbles: true, composed: true}))
        this.dispatchEvent(new CustomEvent("wl-click", {bubbles: true, composed: true}))
    }

}
