import {css, html, LitElement, unsafeCSS} from "lit";
import {pxToRem} from "../styles";
import {WlSelected} from "../events/WlSelected";
import {unsafeHTML} from 'lit/directives/unsafe-html.js';

export class WlAutocompleteOption extends LitElement {
    protected value: string
    protected text: string

    static get properties() {
        return {
            value: {type: String},
            text: {type: String},
            checked: {type: Boolean, reflect: true},
        };
    }

    constructor() {
        super();
        this.value = "";
        this.text = "";
    }

    static get styles() {
        return css`
            :host {
                display: block;
                margin: 0;
                cursor: pointer;
                font-size: var(--wl-control-font-size, ${pxToRem(14)});
                color: var(--wl-color, #3E495F);
            }

            :host(:hover) {
                background-color: var(--wl-hover-background, #2A355A);
                color: var(--wl-hover-color, #FFF);
            }

            .label {
                padding: ${pxToRem(10)} ${pxToRem(9)};
                cursor: pointer;
                width: 100%;
                align-items: center;
                margin: 0;
            }

            input {
                display: none;
                width: ${pxToRem(15)};
                height: ${pxToRem(15)};
                margin-right: ${pxToRem(8)};
                cursor: pointer;
                accent-color: var(--wl-selected-color, #FFF);
            }
        }`
    }

    render() {
        return html`
            <p class="label" @click="${this._check}">${unsafeHTML(this.text)}</p>
        `
    }

    _check() {
        this.dispatchEvent(new WlSelected(this.text, this.value))
    }
}

customElements.define("wl-autocomplete-option", WlAutocompleteOption);