import {css, html, LitElement, nothing} from "lit";
import {pxToRem} from "../styles";
import {WlSelected} from "../events/WlSelected";
import {WlRadioButton} from "../radioButton/radioButton.component";
import {styleMap} from "lit/directives/style-map.js";

const DEFAULT_GAP = 16;

export class WlRadioButtonGroup extends LitElement {
    protected name: string;
    value: string;
    protected label: string;
    private columns?: number | undefined;
    protected header: string;

    static formAssociated = true;

    // @ts-ignore
    private _internals: ElementInternals & IElementInternals;

    static get properties() {
        return {
            value: {type: String, attribute: false},
            name: {type: String},
            columns: {type: Number, reflect: true},
            label: {type: String},
            header: {type: String},
        };
    }

    constructor() {
        super()
        this._internals = this.attachInternals();
        this.name = ""
        this.value = ""
        this.label = ""
        this.header = ""
    }

    static get styles() {
        return css`
            :host > div {
                gap: var(--wl-radio-button-group-gap, ${pxToRem(DEFAULT_GAP)});
            }

            .title {
                display: flex;
                font-style: normal;
                font-weight: 700;
                font-size: ${pxToRem(14)};
                line-height: ${pxToRem(19)};
                color: var(--wl-header-color, #0A0A0A);
                padding-bottom: var(--wl-header-padding-bottom, ${pxToRem(10)});
            }
        `
    }

    protected _renderTitle() {
        if (this.header === "") {
            return nothing
        }
        return html`<span class="title">${this.header}</span>`
    }

    render() {
        return html`
            ${this._renderTitle()}
            <div style="${styleMap(this.containerStyles(this.columns))}">
                <slot @wl-checked="${this.updateValue}"></slot>
            </div>
        `
    }

    firstUpdated() {
        setTimeout(() => {
            this.updateValue()
        }, 0);
    }

    updateValue() {
        const children = this.getChildren()
        const checkedChildren = children
            .filter(child => child.checked);
        if (checkedChildren.length == 1 && checkedChildren[0].value == this.value) {
            return;
        }

        let newValue = "";
        let newLabel = "";
        checkedChildren.forEach(child => {
            if (child.value == this.value) {
                child.unCheck();
            } else {
                newValue = child.value;
                newLabel = child.label();
            }
        });

        this.value = newValue;
        this.label = newLabel;

        this._internals.setFormValue(this.value);
        this.dispatchEvent(new WlSelected(this.label, this.value))
    }

    clear() {
        this.getChildren().forEach(child => child.unCheck())
        this.updateValue();
    }

    private getChildren() {
        return [...this.childNodes]
            .filter(node => node.nodeType === node.ELEMENT_NODE)
            .map(node => node as WlRadioButton)
    }

    private containerStyles(columns: number | undefined) {
        return columns
            ? {display: 'grid', gridTemplateColumns: `repeat(${columns}, 1fr)`}
            : {display: 'flex', flexWrap: 'wrap'};
    }
}
