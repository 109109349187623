export class Value {
    public value: string;
    public label: string;

    constructor(value: string, label: string) {
        this.value = value;
        this.label = label;
    }

    public eq(option: Value) {
        return this.value == option.value && this.label === option.label
    }
}
