import {css, html, LitElement} from "lit";
import {PropertyValues} from "@lit/reactive-element";
import {pxToRem} from "../styles";
import {WlSelected} from "../events/WlSelected";
import {Valuable, ValuableInterface} from "../core/ValuedComponent";
import {WlIntegerInput} from "../integerInput/integerInput.component";

export class WlRange extends Valuable(LitElement) implements ValuableInterface {
    public title: string;
    public priority: string;
    public name: string;
    public form: string | undefined

    static formAssociated = true;

    // @ts-ignore
    private _internals: ElementInternals & IElementInternals | null;

    static get properties() {
        return {
            title: {type: String, reflect: true},
            priority: {type: String, reflect: true},
            name: {type: String, reflect: true},
            disabled: {type: Boolean, reflect: true},
        }
    }

    constructor() {
        super();
        this.title = "";
        this.priority = "";
        this.name = "";
        this.disabled = false;
        this._internals = this.attachInternals();
    }

    clear() {
        super.clear()
        this.getChildren().forEach(child => child.value = null);
    }

    static get styles() {
        return css`
            :host {
                display: flex;
                column-gap: ${pxToRem(4)};
                row-gap: ${pxToRem(6)};
                padding: var(--wl-range-padding, ${pxToRem(10)});
                justify-content: space-between;
                flex-wrap: wrap;
            }

            .title {
                flex-basis: 100%;
                font-size: var(--wl-range-label-font-size, ${pxToRem(16)});
                font-weight: var(--wl-range-label-font-weight, 700);
                color: var(--wl-range-label-color, #000000);
            }
        `
    }

    render() {
        return html`
            ${this.title != '' ? html`<label class="title">${this.title}</label>` : ''}
            <slot @wl-change="${this.updateValue}" @slotchange="${this.updateValue}"></slot>
        `
    }

    update(_changedProperties: PropertyValues) {
        super.update(_changedProperties);
        if (_changedProperties.has("disabled")) {
            this.updateValue()
        }
    }

    private updateValue() {

        if (this.disabled) {
            this._internals.setFormValue(null);
            this.label = "";
            return;
        }

        let children = this.getChildren();
        if (children.length != 2) {
            return;
        }

        let minItem = children[0];
        let maxItem = children[1];

        super.setValue(() => this.getValue(minItem, maxItem), () => this.getLabel(minItem.label, maxItem.label));

        this._internals.setFormValue(this.value);
        this.dispatchEvent(new WlSelected(this.label, this.label));
    }

    private getValue(minItem: WlIntegerInput, maxItem: WlIntegerInput) {
        if (minItem.value == null && maxItem.value == null) {
            return null;
        }

        let value = new FormData()
        if (minItem.value != null) {
            value.append("min-" + this.name, minItem.value.toString())
        }
        if (maxItem.value != null) {
            value.append("max-" + this.name, maxItem.value.toString())
        }
        return value
    }

    private getLabel(priceMin: string, priceMax: string): string {
        if (priceMin == "" && priceMax == "") {
            return ""
        } else {
            return "" + (priceMin || "") + " - " + (priceMax || "");
        }
    }

    private getChildren() {
        return [...this.childNodes]
            .filter(node => node.nodeType === node.ELEMENT_NODE && node instanceof WlIntegerInput)
            .map(node => node as WlIntegerInput)
    }
}
