import {css, html, LitElement} from "lit";
import {pxToRem} from "../styles";
import {WlSelected} from "../events/WlSelected";
import {Valuable} from "../core/ValuedComponent";
import {WlRadioButtonGroup} from "../radioButtonGroup/radioButtonGroup.component";
import {PropertyValues} from "@lit/reactive-element";
import {WlRange} from "../range/range.component";

export class WlMultiLayer extends Valuable(LitElement) {
    public priority: string;
    public visibleLayer: string;

    static get properties() {
        return {
            priority: {type: String, reflect: true},
            visibleLayer: {type: String, reflect: true},
        }
    }

    constructor() {
        super();
        this.priority = "";
        this.visibleLayer = "";
    }

    static get styles() {
        return css`
            :host {
                display: flex;
                flex-direction: column;
                gap: ${pxToRem(16)};
                padding: var(--wl-multilayer-padding, 0);
            }
        `
    }

    render() {
        return html`
            <slot name="selector" @wl-selected="${this.selectLayer}" @slotchange="${this.selectLayer}"></slot>
            <slot name="layers" @wl-selected="${this.valueChange}"></slot>
        `
    }

    clear() {
        this.getLayers().forEach(child => child.clear())
    }

    private selectLayer(event: WlSelected) {
        event.stopPropagation();
        this.visibleLayer = this.getSelectorsValue()
    }

    protected update(changedProperties: PropertyValues) {
        super.update(changedProperties);
        if (changedProperties.has("visibleLayer")) {
            this.getLayers().forEach(layer => this.updateVisibility(layer))
            this.updateValue()
        }
    }

    private updateVisibility(layer: WlLayer) {
        return layer.hidden = (this.visibleLayer != layer.layer);
    }

    private getSelectorsValue() {
        let selectors = this.getSelectors();
        return selectors[0].value
    }

    private getSelectors() {
        return (this.shadowRoot!.querySelector("slot[name='selector']") as HTMLSlotElement)
            .assignedElements()
            .filter(node => node.nodeType === node.ELEMENT_NODE && node instanceof WlRadioButtonGroup)
            .map(node => node as WlRadioButtonGroup);
    }

    private valueChange(event: WlSelected) {
        event.stopPropagation();
        this.updateValue();
    }

    private updateValue() {
        let visibleLayer = this.getVisibleLayer()
        if (visibleLayer.length > 0) {
            this.label = visibleLayer[0].label;
            this.value = visibleLayer[0].value || "";
        }

        this.dispatchEvent(new WlSelected(this.label, this.label));
    }

    private getVisibleLayer() {
       return (this.shadowRoot!.querySelector("slot[name='layers']") as HTMLSlotElement)
            .assignedElements()
            .filter(node => node.nodeType === node.ELEMENT_NODE && node instanceof WlLayer)
            .map(node => node as WlLayer)
            .filter(layer => !layer.hidden)
    }

    private getLayers() {
        return (this.shadowRoot!.querySelector("slot[name='layers']") as HTMLSlotElement)
                ?.assignedElements()
                .filter(node => node.nodeType === node.ELEMENT_NODE && node instanceof WlLayer)
                .map(node => node as WlLayer)
            || []
    }
}

export class WlLayer extends Valuable(LitElement) {
    public hidden: boolean;
    public layer: string;

    static get properties() {
        return {
            hidden: {type: Boolean, reflect: true},
            layer: {type: String, reflect: true},
        }
    }

    constructor() {
        super()
        this.hidden = false;
        this.layer = "";
    }

    static get styles() {
        return css`
            :host {
                display: flex;
            }

            :host([hidden]) {
                display: none;
            }
        `
    }

    render() {
        return html`
            <slot @wl-change="${this.changeValue}"  @wl-selected="${this.changeValue}" @slotchange="${this.slotChange}"></slot>
        `
    }

    private slotChange(event: any) {
        // @ts-ignore
        this.getChildren().forEach(child => child.disabled = this.hidden)
        this.updateValueFromVisibleLayer()
    }

    protected update(changedProperties: PropertyValues) {
        super.update(changedProperties);
        if (changedProperties.has("hidden")) {
            // @ts-ignore
            this.getChildren().forEach(child => child.disabled = this.hidden)
        }
    }

    clear() {
        this.getChildren()
            // @ts-ignore
            .filter(child =>  child.clear != undefined )
            // @ts-ignore
            .forEach(child => child.clear())
        ;
    }

    private changeValue(event: CustomEvent) {
        event.stopPropagation();
        this.updateValueFromVisibleLayer();
    }

    private updateValueFromVisibleLayer() {
        if (this.hidden) {
            return;
        }

        if (this.getRanges().length < 1) {
            return;
        }

        let child: WlRange = this.getRanges()[0];
        super.setValue(() => child.value, () => child.label);
        this.dispatchEvent(new WlSelected(child.label, child.label));
    }

    private getRanges(): WlRange[] {
        return this.getChildren()
            .filter(node => node.nodeType === node.ELEMENT_NODE && node instanceof WlRange)
            .map(node => node as WlRange)
    }

    private getChildren() {
        return [...this.childNodes]
    }
}
