import {css, html, LitElement, nothing} from "lit";
import {pxToRem} from "../styles";

export class Badge extends LitElement {
    static get styles() {
        return css`
            .badge {
                display: flex;
                width: var(--wl-chip-close-icon-size, ${pxToRem(24)});
                min-width: var(--wl-chip-close-icon-size, ${pxToRem(24)});
                max-width: var(--wl-chip-close-icon-size, ${pxToRem(24)});
                height: var(--wl-chip-close-icon-size, ${pxToRem(24)});
                min-height: var(--wl-chip-close-icon-size, ${pxToRem(24)});
                max-height: var(--wl-chip-close-icon-size, ${pxToRem(24)});
                background-color: var(--wl-chip-close-icon-background-color, none);
                color: var(--wl-chip-close-icon-color, var(--wl-chip-color, white));
                border-radius: 50%;
                align-items: center;
                justify-content: center;
            }
        `
    }

    protected render() {
        return html`<slot class="badge"></slot>`
    }
}