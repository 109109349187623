import {css, html, LitElement, nothing} from "lit";
import {pxToRem} from "../styles";
import {WlSelected} from "../events/WlSelected";
import {WlCheckbox} from "../checkbox/checkbox.component";
import {styleMap} from "lit/directives/style-map.js";

export class WlTree extends LitElement {
    protected name: string;
     value: string[];
    protected label: string[];
    public formatLabel: (value: string[], label: string[]) => string;
    protected columns: number;

    static formAssociated = true;

    // @ts-ignore
    private _internals: ElementInternals & IElementInternals;

    constructor() {
        super()
        this._internals = this.attachInternals();
        this.name = ""
        this.value = []
        this.label = []
        this.columns = 1
        this.formatLabel = (value, label) => label.join(", ")
    }

    static get properties() {
        return {
            value: {type: String, attribute: false},
            name: {type: String},
            columns: {type: Number},
        };
    }

    render() {
        return html`
            <slot @change="${this.updateValue}" @firstchange="${this.firstUpdate}"
                  style="${styleMap(this.columnsStyle(this.columns))}"></slot>
        `
    }

    firstUpdate() {
        setTimeout(() => {
            this.updateValue()
        }, 0);
    }

    forceUpdate() {
        this.updateValue(true)
    }

    updateValue(force: boolean = false) {
        const children = this.getChildren()
        const newValue = children
            .map(child => (child.getValue()))
            .flat()
        const newLabel = children
            .map(child => (child.getSelectedLabels()))
            .flat()

        if (!force && this.areTheSame(this.value, newValue) && this.areTheSame(this.label, newLabel)) {
            return
        }
        this.value = newValue
        this.label = newLabel
        let emittedLabel = this.formatLabel(newValue, newLabel)

        this._internals.setFormValue(this.value.join(","));
        this.dispatchEvent(new WlSelected(emittedLabel, this.value.join(",")))
    }

    clear() {
        this.getChildren().forEach(child =>child.uncheck())
        this.updateValue();
    }

    private areTheSame(array1: string[], array2: string[]) {
        return array1.length == array2.length && array1.every(v => array2.includes(v));
    }

    private getChildren() {
        return [...this.childNodes]
            .filter(node => node.nodeType === node.ELEMENT_NODE)
            .map(node => node as WlTreeItem)
    }

    private columnsStyle(columns: number | undefined) {
        return columns
            ? {display: 'grid', gridTemplateColumns: `repeat(${columns}, 1fr)`}
            : {display: 'flex', flexWrap: 'wrap'};
    }
}

export class WlTreeItem extends LitElement{
    protected value: string;
    protected checked: boolean;
    protected indeterminate: boolean;
    protected closed: boolean;
    protected fixedOpen: boolean;
    protected doubleColumn: boolean;
    protected checkboxComponent!: WlCheckbox;
    private _withChildren: boolean
    protected columned: boolean;
    private childrenSlot: HTMLSlotElement | null
    private readonly checkable: boolean;


    constructor() {
        super()
        this.value = "";
        this.checked = false;
        this.indeterminate = false;
        this.closed = false;
        this._withChildren = false;
        this.childrenSlot = null;
        this.fixedOpen = false;
        this.doubleColumn = false;
        this.columned = false;
        this.checkable = false;
    }

    connectedCallback() {
        super.connectedCallback();
        const parent = this.parentElement
        const hasParent = parent!! && parent?.tagName?.toLowerCase() == "wl-tree-item"
        if (hasParent) {
            this.slot = "children"
            if ((parent as WlTreeItem).checked) {
                this.checked = true;
            }
            if ((parent as WlTreeItem).doubleColumn) {
                this.columned = true;
            }
        }
        if (this.fixedOpen) {
            this.closed = false
        }
    }

    firstUpdated(changedProperties: any) {
        this.childrenSlot = this.shadowRoot!.querySelector("slot[name=children]")!
        this.checkboxComponent = this.renderRoot!.querySelector("wl-checkbox")!

        if (this.checked) {
            this.check()
        }

        this.dispatchEvent(new CustomEvent("firstchange", {bubbles: true, composed: true}))
    }

    updated() {
        this.updateCheckbox()
    }

    static get properties() {
        return {
            value: {type: String},
            checked: {type: Boolean, attribute: true, reflect: true},
            indeterminate: {type: Boolean, attribute: true, reflect: true},
            closed: {type: Boolean, attribute: true, reflect: true},
            _withChildren: {type: Boolean, state: true},
            fixedOpen: {type: Boolean, attribute: true, reflect: true},
            doubleColumn: {type: Boolean, attribute: true, reflect: true},
            columned: {type: Boolean, reflect: true},
            checkable: {type: Boolean, attribute: true, reflect: true},
            accumulative: {type: Boolean, attribute: true, reflect: true}
        };
    }

    static get styles() {
        return css`
            :host {
                display: flex;
                flex-direction: column;
                font-size: var(--wl-item-font-size, var(--wl-control-font-size, ${pxToRem(14)}));
            }
            
            :host([columned]) {
                width: 50%;
            }

            .selector {
                width: 100%;
                display: flex;
                height: var(--wl-checkbox-item-height, auto);
            }

            .selector:not(.no-checkable):hover {
                background-color: var(--wl-item-hover-background-color, inherit);
            }

            .selector label, .selector wl-checkbox {
                flex-grow: 1;
                padding: var(--wl-item-padding-top-botton, ${pxToRem(12)}) ${pxToRem(14)};
                height: auto;
                display: flex;
            }
            
            :host([istitle]) .selector label {
                font-weight: 700;
                color: var(--wl-item-is-title-color, #0A0A0A);
            }

            .opener {
                cursor: pointer;
                width: var(--wl-item-height, ${pxToRem(40)});
                height: var(--wl-item-height, ${pxToRem(40)});
                display: inline-block;
                position: relative;
            }

            .opener i:after {
                content: "";
                display: block;
                box-sizing: border-box;
                position: absolute;
                width: ${pxToRem(8)};
                height: ${pxToRem(8)};

                top: ${pxToRem(20)};
                color: ${pxToRem(15)};
                border-bottom: ${pxToRem(2)} solid;
                border-right: ${pxToRem(2)} solid;
                transform: rotate(225deg);

                right: ${pxToRem(15)};
            }

            :host([closed]) .opener i:after {
                transform: rotate(45deg);
                top: ${pxToRem(15)};
            }

            slot[name="children"] {
                display: flex;
                flex-direction: column;
                padding-left: 30px;
                width: auto;
            }

            :host([closed]) > slot[name="children"] {
                display: none;
            }

            :host([doubleColumn]) > slot[name="children"] {
                flex-direction: row;
                flex-wrap: wrap;
                padding-left: 10px;
            }

            :host([doubleColumn]) > slot[name="children"]  * {
                width: 50%;
            }
        `
    }

    render() {
        return html`
            <div class="selector ${this.checkable ? "" : "no-checkable"}">
                ${this.getTitle()}
                ${this.renderOpener()}
            </div>
            <slot name="children" @change="${this.setStateBasedOnChildren}"
                  @firstchange="${this.setStateBasedOnChildrenFirst}"></slot>
        `
    }

    private getTitle() {
        if (this.checkable) {
            return html`
                <wl-checkbox
                        @wl-checked="${this.setStateBasedOnCheckbox}"
                >
                    <slot></slot>
                </wl-checkbox>`;
        } else {
            return html`<label><slot></slot></label>`
        }
    }

    public uncheck() {
        this.checked = false;
        this.indeterminate = false;
        this.uncheckChildren()
    }

    public check() {
        this.checked = true;
        this.indeterminate = false;
        this.checkChildren()
    }

    public getValue() {
        if (this.checked) return [this.value]
        if (this.indeterminate) return this.getChildrenValues()
        else return []
    }

    public getSelectedLabels() {
        if (this.checkable && this.checked) return [this.getLabel()]
        if (!this.checkable || this.indeterminate) return this.getChildrenLabels()
        else return []
    }

    public getChildrenValues(): string[] {
        if (this.getChildItems().length == 0) {
            return []
        }

        return this.getChildItems()
            .filter(child => child.checked)
            .map(selectedChild => selectedChild.getValue())
            .flat()
    }

    public getChildrenLabels(): string[] {
        if (this.getChildItems().length == 0) {
            return []
        }

        return this.getChildItems()
            .filter(child => child.checked)
            .map(selectedChild => selectedChild.getSelectedLabels())
            .flat()
    }

    private setStateBasedOnChildrenFirst() {
        setTimeout(() => {
            this.setStateBasedOnChildren()
        }, 0)
    }

    private renderOpener() {
        return (this._withChildren && !this.fixedOpen)
            ? html`<span class="opener" @click="${this.toggle}"><i></i></span>`
            : nothing
    }

    private getLabel(): string {
        const labelNode = [...this.childNodes]
            .find(node => node.nodeType === node.TEXT_NODE && node.textContent!.trim() !== '')
        return labelNode?.textContent?.trim() || ""
    }

    private toggle() {
        this.closed = !this.closed
    }

    private getChildItems() {
        const childItems = this.childrenSlot
            ?.assignedElements()
            .map(node => node as WlTreeItem) || []

        this._withChildren = childItems.length > 0

        return childItems
    }

    private setStateBasedOnCheckbox() {
        if (this.checkboxComponent?.checked) {
            this.check()
        } else {
            this.uncheck()
        }
        this.dispatchEvent(new CustomEvent("change", {bubbles: true, composed: true}))
    }

    private setStateBasedOnChildren() {
        const childItems = this.getChildItems()
        if (this.getChildItems().length == 0) {
            return
        }

        const checkedChildren = childItems.filter(child => child.checked)
        if (checkedChildren.length == 0) {
            this.uncheck()
        } else if (checkedChildren.length == childItems.length) {
            this.check()
        } else {
            this.checkPartially()
        }

        this.dispatchEvent(new CustomEvent("change", {bubbles: true, composed: true}))
    }

    private checkChildren() {
        this.getChildItems().forEach(child => child.check())
    }

    private uncheckChildren() {
        this.getChildItems().forEach(child => child.uncheck())
    }

    private checkPartially() {
        this.checked = false;
        this.indeterminate = true;
    }

    private updateCheckbox() {
        if (this.checkboxComponent){
            this.checkboxComponent.checked = this.checked
            this.checkboxComponent.indeterminate = this.indeterminate
        }
    }
}
