export interface FavoriteCookie {
    listings: Set<string>;
}

export class FavoritesCookie {

    static FAVORITES_COOKIE_NAME: string = '_wl_favorites';
    private readonly FAVORITES_COOKIE_EXPIRATION_DAYS: number = 365;

    setFavorites(favoriteCookie: FavoriteCookie) {
        const value: string = JSON.stringify(
            favoriteCookie,
            (_key: string, value: any): any => (value instanceof Set ? [...value] : value)
        );
        const expirationTimeInMs: number = this.FAVORITES_COOKIE_EXPIRATION_DAYS * 24 * 60 * 60 * 1000;
        const date: Date = new Date();
        date.setTime(date.getTime() + expirationTimeInMs);
        const expires: string = `expires=${date.toUTCString()}`;
        const htmlEncodedValue: string = encodeURIComponent(value);
        document.cookie = `${FavoritesCookie.FAVORITES_COOKIE_NAME}=${htmlEncodedValue}; ${expires}; Path=/`;
    }

    getFavorites(): FavoriteCookie {
        const cookieList: string[] = document.cookie.split(';');
        const cookie = cookieList.find(c => c.trim().startsWith(FavoritesCookie.FAVORITES_COOKIE_NAME));
        const empty: FavoriteCookie = {
            listings: new Set<string>(),
        };
        if (cookie) {
            const value: string = cookie.trim().substring(FavoritesCookie.FAVORITES_COOKIE_NAME.length + 1);
            try {
                return this.safeJsonParse<FavoriteCookie>(decodeURIComponent(value), empty);
            } catch (e) {
                return empty;
            }
        }
        return empty;
    }

    private safeJsonParse<T>(json: string, defaultValue: T): T {
        try {
            const rawParsed = JSON.parse(
                json,
                (_key: string, value: any): any => (Array.isArray(value) ? new Set(value) : value)
            );
            if (!rawParsed) return defaultValue;
            for (const key in defaultValue) {
                if (typeof rawParsed[key] !== typeof defaultValue[key]) {
                    return defaultValue;
                }
            }
            return rawParsed as T;
        } catch (e) {
            return defaultValue;
        }
    }
}